@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #3eb489;
  --bs-link-color: #26bd95;
  --bs-link-hover-color: #099872;
  --bs-primary-rgb: 62, 180, 137;
}
.no-select {
  user-select: none;
}
.pointer {
  cursor: pointer;
}
input[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
}
.scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.scrollbar::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #929ca7;
}
.loading-outer {
  width: 6rem;
  height: 6rem;
  position: relative;
  border-width: 2px;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
  border-color: #687fa6;
}
.loading-outer::before {
  left: -2px;
  top: -2px;
  border-color: transparent #687fa6 transparent #687fa6;
  content: "";
  width: 6rem;
  height: 6rem;
  position: absolute;
  border-radius: 50%;
  border-width: 6px;
}
.loading-inner::before {
  top: -6px;
  left: -6px;
  width: calc(6rem + 8px);
  height: calc(6rem + 8px);
  position: absolute;
  border-width: 6px;
  content: "";
  border-radius: 50%;
  border-color: #687fa6 transparent #687fa6 transparent;
  animation: counter-spin 0.7s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes counter-spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
